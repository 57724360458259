import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

import One from "../images/press/1.jpg";
import Two from "../images/press/2.jpg";
import Three from "../images/press/3.jpg";

function PressPage() {
  return (
    <Layout>
      <SEO title="Press" />

      <div className="wrapper pt-16 plantin min-h-screen bg-orange-100">
        <div className="flex flex-col max-w-4xl mx-auto text-center">
          <h2 className="text-3xl font-bold">Contact</h2>
          <div className="text-center">
            <h3 className="text-2xl">Media inquiries</h3>
            <p>
              Amy Franklin
              <br />
              Franklin Public Relations
              <br /> 646-489-0300
              <br />
              amy@franklinpublicrelations.com
            </p>
          </div>
          <div className="text-center">
            <h3 className="text-2xl">Jason Diakité Management</h3>
            <p>
              Björn Barnekow
              <br />
              Bad Taste Empire Inc. <br /> +1-818-448-2597 / +46-707-425121
              <br />
              bjorn@badtasteempire.com
            </p>
          </div>
          <h2 className="text-3xl font-bold">Press photos</h2>
          <div className="text-center my-2">
            <div className="my-4 w-48 mx-auto">
              <img src={One} />

              <p className="text-sm my-2">
                <a
                  href="https://www.dropbox.com/s/5lz20as8grkgw9i/Timbuktu_Photo_By_Therese_%C3%96hrvall__7423.jpg?dl=1"
                  className="underline text-blue-500 hover:text-blue-700"
                >
                  Download High Res
                </a>
              </p>
            </div>
            <div className="my-4 w-32 mx-auto">
              <img src={Two} />
              <p className="text-sm my-2">
                <a
                  href="https://www.dropbox.com/s/zh6w0c0vmw3gh8a/Timbuktu_Photo_By_Therese_%C3%96hrvall_7443.jpg?dl=1"
                  className="underline text-blue-500 hover:text-blue-700"
                >
                  Download High Res
                </a>
              </p>
            </div>
            <div className="my-4 w-48 mx-auto">
              <img src={Three} />
              <p className="text-sm my-2">
                <a
                  href="https://www.dropbox.com/s/g8nji73omhaspka/Timbuktu_Photo_By_Therese_%C3%96hrvall_6849.jpg?dl=1"
                  className="underline text-blue-500 hover:text-blue-700"
                >
                  Download High Res
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default PressPage;
